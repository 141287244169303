/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import s from './FiltersHeader.scss';
import {X} from '../../../../icons/dist';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import classNames from 'classnames';
import {ProductsCounter} from '../../../../category/components/ProductsCounter/ProductsCounter';

export interface IFiltersHeaderProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  combineFiltersAndSort?: boolean;
  closeMobileFiltersModal: Function;
  modalHeaderId?: string;
}

export class FiltersHeaderComp extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {
      shouldShowMobile,
      isCategoryPage,
      styles,
      stylesParams,
      applyFilteredProductsOnMobile,
      experiments: {shouldApplyMobileFiltersEnhancements},
    } = this.props.globals;
    const {combineFiltersAndSort} = this.props;
    const shouldShowFilterTitle = styles.get(stylesParams.gallery_showFiltersTitle);
    return (
      <span
        className={classNames(s.header, {
          [s.categoryPage]: isCategoryPage,
          [s.shouldNotShowFilterTitle]: !shouldShowFilterTitle,
        })}>
        {shouldShowFilterTitle && (
          <h2
            data-hook="filters-title"
            className={classNames(s.title, {[s.categoryPage]: isCategoryPage})}
            id={this.props.modalHeaderId}>
            <span>
              {combineFiltersAndSort ? this.props.t('mobileFiltersAndSortingText') : this.props.t('filtersTitleText')}
            </span>
            {shouldShowMobile && shouldApplyMobileFiltersEnhancements && (
              <>
                <span>&nbsp;</span>
                <ProductsCounter mobileInlineMode={true} />
              </>
            )}
          </h2>
        )}
        {shouldShowMobile && (
          <button
            type="button"
            data-hook="cancel"
            aria-label={this.props.t('filtersAriaLabel')}
            className={s.cancel}
            onClick={() =>
              shouldApplyMobileFiltersEnhancements
                ? applyFilteredProductsOnMobile()
                : this.props.closeMobileFiltersModal()
            }>
            <X />
          </button>
        )}
      </span>
    );
  }
}

export const FiltersHeader = withGlobals(withTranslations()(FiltersHeaderComp));
